import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import bytes from 'bytes'

// Elements
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'


const
  styles = (theme) => ({
    fileIcon: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
    fileIconImage: {
      width: theme.spacing.unit * 2,
      height: theme.spacing.unit * 2,
    },
  })

@withStyles(styles)
export default class FoldersFolder extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
  }

  render() {
    const { file } = this.props
    const { name, size } = file
    const { classes } = this.props
    return (
      <Fragment key={name}>
        <ListItem>
          <Avatar className={classes.fileIcon}>
            <ImageIcon className={classes.fileIconImage} />
          </Avatar>
          <ListItemText primary={name} secondary={bytes(size)} />
        </ListItem>
        <Divider light />
      </Fragment>
    )
  }
}
