import React from 'react'
import { Route } from 'react-router-dom'
import Switch from 'react-router-transition-group'
import Fade from '@material-ui/core/Fade'
import GuestLayout from 'components/Layout/GuestLayout'

// Pages
import LandingPage from './Landing'

export default () => (
  <GuestLayout>
    <Switch transition={Fade}>
      <Route component={LandingPage} />
    </Switch>
  </GuestLayout>
)
