import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

// Atoms
import Link from 'atoms/Link'

// Elements
import Avatar from '@material-ui/core/Avatar'
import FolderIcon from '@material-ui/icons/Folder'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'


const
  styles = (theme) => ({
    fileIcon: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
    fileIconImage: {
      width: theme.spacing.unit * 2,
      height: theme.spacing.unit * 2,
    },
  })

@withStyles(styles)
export default class FoldersFolder extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    folder: PropTypes.object.isRequired,
  }

  render() {
    const { folder } = this.props
    const { path, name } = folder
    const { classes } = this.props
    return (
      <Fragment key={path}>
        <Link to={`/dropbox/list${path}`}>
          <ListItem button>
            <Avatar className={classes.fileIcon}>
              <FolderIcon className={classes.fileIconImage} />
            </Avatar>
            <ListItemText primary={name} />
          </ListItem>
        </Link>
        <Divider light />
      </Fragment>
    )
  }
}
