import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import ImageThumbnail from 'components/ImageThumbnail'

const
  styles = theme => ({
    images: {
      padding: 0,
      paddingTop: theme.spacing.unit * 1,
    }
  })

@withStyles(styles)
export default class ImageGrid extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    images: PropTypes.array.isRequired,
  }

  render() {
    const { classes, images } = this.props
    return (
      <Grid container className={classes.images} spacing={16}>
        {
          images.map(({ uuid }) => (
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2} key={uuid}>
              <ImageThumbnail imageUUID={uuid} />
            </Grid>
          ))
        }
      </Grid>
    )
  }
}
