// This works for entities where all list is not same as single entity

import { replaceEntities, replaceCollections } from '@fakundo/redux-entities'
import { IMAGE } from 'constants/entityNames'
import keyBy from 'lodash/keyBy'

const ENTITY = IMAGE
const API_ENTITY = 'image'
const API_ENTITY_LIST = '/list'

// Get all entities
export const fetchEntities = () =>
  (dispatch, getState, api) =>
    api({
      apiPath: `${API_ENTITY}/${API_ENTITY_LIST}`,
    })

// Reload all entities
export const reloadEntities = cancelToken =>
  async (dispatch) => {
    const data = await dispatch(fetchEntities(cancelToken))
    dispatch(replaceCollections({ [ENTITY]: { ...keyBy(data, 'uuid') } }))
  }

export const fetchEntity = (entityUUID, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'get',
      apiPath: `${API_ENTITY}/${entityUUID}`,
      cancelToken
    })
    return entity
  }

export const reloadEntity = (entityUUID, cancelToken) =>
  async (dispatch) => {
    const entity = await dispatch(fetchEntity(entityUUID, cancelToken))
    dispatch(replaceEntities(ENTITY, { [entityUUID]: entity }))
  }

export const updateEntity = (entityUUID, data, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'patch',
      apiPath: `${API_ENTITY}/${entityUUID}`,
      data,
      cancelToken
    })
    await dispatch(reloadEntity(entityUUID))
    return entity
  }

export const addTag = (entityUUID, tag, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'post',
      apiPath: `${API_ENTITY}/${entityUUID}/tag`,
      data: {
        tag,
      },
      cancelToken
    })
    await dispatch(reloadEntity(entityUUID))
    return entity
  }


export const removeTag = (entityUUID, tag, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'delete',
      apiPath: `${API_ENTITY}/${entityUUID}/tag`,
      data: {
        tag,
      },
      cancelToken
    })
    await dispatch(reloadEntity(entityUUID))
    return entity
  }
