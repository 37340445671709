import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import LinearProgress from '@material-ui/core/LinearProgress'


const
  styles = {
    root: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1500,
    },
  }

@withStyles(styles)
export default class ProgressBarOnTop extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  render() {
    const { classes } = this.props
    return (
      <LinearProgress className={classes.root} />
    )
  }
}
