import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'

// Atoms
import ProgressBarOnTop from 'atoms/ProgressBar/ProgressBarOnTop'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import Avatar from '@material-ui/core/Avatar'
import Link from 'atoms/Link'
import Divider from '@material-ui/core/Divider'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import LanguageIcon from '@material-ui/icons/Language'
import AddLocationIcon from '@material-ui/icons/AddLocation'

import Button from '@material-ui/core/Button'

// Actions
import { makeGetLocation } from 'selectors/locations'

import NewLocationDialog from 'components/Dialogs/NewLocation'

const
  styles = (theme) => ({
    fileIcon: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
    fileIconImage: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
    nested: {
      paddingLeft: theme.spacing.unit * 8,
    },
  })

const mapGettersToProps = () => {
  const getLocation = makeGetLocation()
  return (state, { uuid, parentUUID }) => ({
    locationGetter: getLocation(state, { uuid }),
    parentGetter: getLocation(state, { uuid: parentUUID }),
  })
}

@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class LocationsLocation extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    back: PropTypes.bool,
    first: PropTypes.bool,
    hideEmpty: PropTypes.bool,
    locationGetter: PropTypes.object.isRequired,
    parentGetter: PropTypes.object.isRequired,
  }

  state = {
    dialogOpen: false,
  }

  openDialog = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      dialogOpen: true
    })
  }

  closeDialog = () => {
    this.setState({
      dialogOpen: false
    })
  }


  render() {
    const { locationGetter, parentGetter, classes, back, first, hideEmpty } = this.props
    const { dialogOpen } = this.state

    if (!locationGetter.isSucceded || !parentGetter.isSucceded) {
      return <ProgressBarOnTop />
    }
    const
      main = locationGetter.data,
      { name, childrenBatches = [], batches = [] } = main,
      parent = parentGetter.data,
      isBack = back || first,
      uuid = isBack ? (parent.uuid) : main.uuid,
      realName = isBack ? (parent.name) : main.name,
      batchCount = (batches.length + childrenBatches.length),
      batchText = batchCount ? `${batchCount}` : 'No material',
      disabled = batchCount === 0,
      link = `/location/${uuid || main.uuid}/${realName || main.name}`,
      className = !isBack ? classes.nested : undefined


    if (batchCount === 0 && hideEmpty && !back && !first) {
      return false
    }


    return (
      <Fragment>
        <NewLocationDialog
          open={dialogOpen}
          onClose={this.closeDialog}
          parentUUID={main.uuid}
        />
        <Link disabled={!!uuid} to={link}>
          <ListItem button={!!uuid} className={className} disabled={disabled}>
            { back
              && (
                <Avatar className={classes.fileIcon}>
                  <KeyboardArrowLeftIcon className={classes.fileIconImage} />
                </Avatar>
              )
            }
            { first
              && (
                <Avatar className={classes.fileIcon}>
                  <LanguageIcon className={classes.fileIconImage} />
                </Avatar>
              )
            }
            <ListItemText primary={name} secondary={batchText} />
            {
              (first || back) && (
                <ListItemSecondaryAction>
                  <Button variant="contained" color="primary" className={classes.button} onClick={this.openDialog}>
                    <AddLocationIcon className={classes.addLocationIcon} />
                    Add location
                  </Button>
                </ListItemSecondaryAction>
              )
            }
          </ListItem>
        </Link>
        <Divider />
      </Fragment>
    )
  }
}
