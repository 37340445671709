import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'

const
  styles = {
    root: {
      background: '#586580',
    },
  }

@withStyles(styles)
export default class Title extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
  }

  render() {
    const { title, classes } = this.props
    return (
      <AppBar position="sticky" className={classes.root} color="secondary">
        <Toolbar variant="dense">
          <Typography variant="title" color="inherit">
            { title }
          </Typography>
        </Toolbar>
      </AppBar>
    )
  }
}
