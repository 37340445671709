// Misc
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

// Styles
const styles = (theme) => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing.unit * 8,
  }
})

@withStyles(styles)
export default class GuestLayout extends Component {
  static propTypes = {
    children: PropTypes.any,
    classes: PropTypes.object.isRequired,
  }

  render() {
    const { children, classes } = this.props
    return (
      <div className={classes.root}>
        { children }
      </div>
    )
  }
}
