import { createGetter } from 'react-redux-getters'
import { fetchMe, updateMe } from 'actions/me'

export const getMe = createGetter({
  stateSelector: state => state.me,
  shouldFetch: (selectedData) => {
    return selectedData === null
  },
  asyncFetcher: (dispatch) => {
    return dispatch(fetchMe(null))
  },
  stateUpdater: (me, dispatch) => {
    return dispatch(updateMe(me))
  },
})
