import { createSelector } from 'reselect'
import { composeGetters } from 'react-redux-getters'
import filter from 'lodash/filter'

import { BATCH, BATCHES } from 'constants/entityNames'
import { fetchEntities, fetchEntity } from 'actions/batch'

import { generateSelectors } from './helpers'

const {
  getEntities,
  getEntity,
  makeGetEntity,
  makeGetEntityGlobal,
} = generateSelectors(BATCH, BATCHES, { fetchEntities, fetchEntity })

export const getBatches = getEntities
export const getBatch = getEntity
export const makeGetBatch = makeGetEntity
export const makeGetBatchGlobal = makeGetEntityGlobal

export const makeGetBatchesForLocation = () => createSelector(
  state => getEntities(state),
  (state, { uuid }) => uuid,
  (entitiesGetter, uuid) => composeGetters(
    entitiesGetter,
    (entities) => filter(entities, ({ locationUUID }) => locationUUID === uuid)
  )
)

export const makeGetBatchesForUUIDS = () => createSelector(
  state => getEntities(state),
  (state, { uuids }) => uuids,
  (entitiesGetter, uuids) => composeGetters(
    entitiesGetter,
    (entities) => filter(entities, ({ uuid }) => uuids.indexOf(uuid) !== -1)
  )
)

export const makeGetBatchForIntegrationPath = () => createSelector(
  state => getEntities(state),
  (state, { integrationUUID, path }) => ({ integrationUUID, path }),
  (entitiesGetter, { integrationUUID, path }) => composeGetters(
    entitiesGetter,
    (entities) => filter(entities, (entity) =>
      entity.integrationUUID === integrationUUID && entity.path === path)
  )
)
