import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'

// Atoms
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import EditIcon from '@material-ui/icons/Edit'
import Avatar from '@material-ui/core/Avatar'
import LocationPath from 'atoms/LocationPath'
import IconButton from '@material-ui/core/IconButton'

// Actions
import { makeGetLocationChain } from 'selectors/locations'

import EditBatchDialog from 'components/Dialogs/EditBatchDialog'

const
  styles = (theme) => ({
    fileIcon: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
    fileIconImage: {
      width: theme.spacing.unit * 2,
      height: theme.spacing.unit * 2,
    },
    editButton: {
    },
    text: {
      paddingRight: theme.spacing.unit * 3
    }
  })

const mapGettersToProps = () => {
  const getLocation = makeGetLocationChain()
  return (state, { batch: { locationUUID } }) => ({
    locationGetter: getLocation(state, { uuid: locationUUID }),
  })
}

@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class BatchPreview extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    batch: PropTypes.object.isRequired,
    button: PropTypes.bool,
    locationGetter: PropTypes.object.isRequired,
  }

  state = {
    dialogOpen: false,
  }

  openDialog = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      dialogOpen: true
    })
  }

  closeDialog = () => {
    this.setState({
      dialogOpen: false
    })
  }

  render() {
    const { locationGetter, classes, batch, button = false } = this.props
    const { dialogOpen } = this.state

    if (!locationGetter.isSucceded) {
      return false
    }

    const location = locationGetter.data

    const summary = (
      <Fragment>
        <LocationPath location={location} links />
      </Fragment>
    )

    return (
      <Fragment>
        <EditBatchDialog
          open={dialogOpen}
          onClose={this.closeDialog}
          batchUUID={batch.uuid}
        />
        <ListItem button={button}>
          <Avatar className={classes.fileIcon}>
            <FolderSharedIcon className={classes.fileIconImage} />
          </Avatar>
          <ListItemText
            className={classes.text}
            primary={batch.caption}
            secondary={summary}
          />
          <ListItemSecondaryAction>
            <IconButton variant="outlined" color="primary" className={classes.editButton} onClick={this.openDialog} component="span">
              <EditIcon className={classes.fileIconImage} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider light />
      </Fragment>
    )
  }
}
