import { createSelector } from 'reselect'
import { createGetter, composeGetters } from 'react-redux-getters'
import { getCollection, replaceCollections, replaceEntities, getEntity } from '@fakundo/redux-entities'
import keyBy from 'lodash/keyBy'
import find from 'lodash/find'

export const generateGetEntities = (ALL, fetchEntities) => createGetter({
  stateSelector: state => getCollection(state, ALL),
  asyncFetcher: dispatch => dispatch(fetchEntities()),
  stateUpdater: (entities, dispatch) => dispatch(replaceCollections({
    [ALL]: keyBy(entities, 'uuid')
  })),
})

export const generateGetEntity = (ONE, fetchEntity) => createGetter({
  stateSelector: (state, params) => (params.uuid ? getEntity(state, ONE, params.uuid) : {}),
  asyncFetcher: (dispatch, state, params) => dispatch(fetchEntity(params.uuid)),
  stateUpdater: (entity, dispatch) => dispatch(replaceEntities(ONE, { [entity.uuid]: entity })),
})

export const generateMakeGetEntity = getEntityFunction => () => createSelector(
  state => getEntityFunction(state),
  (state, { uuid }) => uuid,
  (entitiesGetter, entityUUID) => composeGetters(
    entitiesGetter,
    (entities) => find(entities, ({ uuid }) => uuid === entityUUID) || {}
  )
)

export const generateSelectors = (ENTITY, ALL_ENTITIES, { fetchEntities, fetchEntity }) => {
  const
    getEntities = generateGetEntities(ALL_ENTITIES, fetchEntities),
    getOneEntity = generateGetEntity(ENTITY, fetchEntity)

  return {
    getEntities,
    getEntity: getOneEntity,
    makeGetEntity: generateMakeGetEntity(getOneEntity),
    makeGetEntityGlobal: generateMakeGetEntity(getEntities),
  }
}

export const generateSelectorsSingle = (ENTITY, { fetchEntities, fetchEntity }) => {
  const
    getEntities = generateGetEntities(ENTITY, fetchEntities),
    getOneEntity = generateGetEntity(ENTITY, fetchEntity)

  return {
    getEntities,
    getEntity: getOneEntity,
    makeGetEntity: generateMakeGetEntity(getOneEntity),
    makeGetEntityGlobal: generateMakeGetEntity(getEntities),
  }
}
