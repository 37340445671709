import { replaceEntities, getCollection } from '@fakundo/redux-entities'
import { LOCATIONS } from 'constants/entityNames'
import keyBy from 'lodash/keyBy'

const ENTITY = LOCATIONS
const API_ENTITY = 'location'


const replaceLocationEntities = (dispatch, getState, entities) => {
  if (getCollection(getState(), ENTITY)) {
    dispatch(replaceEntities(ENTITY, entities))
  }
}

export const fetchLocations = () =>
  (dispatch, getState, api) =>
    api({
      apiPath: API_ENTITY,
    })

const reloadLocations = async (dispatch, getState, api) => {
  const locations = await fetchLocations()(dispatch, getState, api)
  replaceLocationEntities(dispatch, getState, keyBy(locations, 'uuid'))
}

export const createLocation = (data, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'post',
      apiPath: API_ENTITY,
      data,
      cancelToken
    })
    reloadLocations(dispatch, getState, api)
    return entity
  }

export const addTag = (tag, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'post',
      apiPath: `${API_ENTITY}/tag`,
      data: { tag },
      cancelToken
    })
    reloadLocations(dispatch, getState, api)
    return entity
  }

export const removeTag = (tag, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'delete',
      apiPath: `${API_ENTITY}/tag`,
      data: { tag },
      cancelToken
    })
    reloadLocations(dispatch, getState, api)
    return entity
  }
