import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'

// Atoms
import ProgressBarOnTop from 'atoms/ProgressBar/ProgressBarOnTop'
import List from '@material-ui/core/List'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'

// Actions
import { makeGetLocationChain } from 'selectors/locations'

import NewLocationDialog from 'components/Dialogs/NewLocation'
import Title from 'atoms/Title'
import BatchList from 'components/BatchList'

import SingleLocation from './Single'


const
  TAB_ALL = 'TAB_ALL',
  TAB_FULL = 'TAB_FULL'

const
  styles = (theme) => ({
    list: {
      background: '#fff',
    },
    fileIcon: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
    fileIconImage: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
  })

const mapGettersToProps = () => {
  const getLocation = makeGetLocationChain()
  return (state, { uuid }) => ({
    locationGetter: getLocation(state, { uuid }),
  })
}

@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class LocationsLocation extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    locationGetter: PropTypes.object.isRequired,
  }

  state = {
    newLocationDialogOpen: false,
    tab: TAB_FULL,
  }

  openNewLocationDialog = () => {
    this.setState({
      newLocationDialogOpen: true
    })
  }

  closeNewLocationDialog = () => {
    this.setState({
      newLocationDialogOpen: false
    })
  }

  handleChangeTab = (event, tab) => {
    this.setState({
      tab
    })
  }

  render() {
    const { locationGetter, classes } = this.props
    const { newLocationDialogOpen, tab } = this.state

    if (!locationGetter.isSucceded || !locationGetter.isSucceded) {
      return <ProgressBarOnTop />
    }

    const
      { children, uuid, parentUUID, childrenBatches = [] } = locationGetter.data,
      hideEmpty = tab === TAB_FULL

    return (
      <Fragment>
        <NewLocationDialog
          open={newLocationDialogOpen}
          onClose={this.closeNewLocationDialog}
          parentUUID={uuid}
        />
        <List className={classes.list}>
          <Title title="Locations" />
          <SingleLocation
            uuid={uuid}
            parentUUID={parentUUID}
            back={!!parentUUID}
            first={!parentUUID}
          />
          <Tabs value={tab} onChange={this.handleChangeTab}>
            <Tab value={TAB_ALL} label="All locations" />
            <Tab value={TAB_FULL} label="Not empty" />
          </Tabs>
          <Divider />
          {
            children.map(locationUUID =>
              <SingleLocation key={locationUUID} uuid={locationUUID} hideEmpty={hideEmpty} />)
          }
          {
            !!parentUUID && (
              <BatchList
                locationUUID={uuid}
                batchUUIDList={childrenBatches}
                title="Data points"
                secondaryTitle="Children data points"
                emptyTitle="Empty"
              />
            )
          }
        </List>
      </Fragment>
    )
  }
}
