// import { createSelector } from 'reselect'
// import { composeGetters } from 'react-redux-getters'
// import filter from 'lodash/filter'

/*

import { IMAGE } from 'constants/entityNames'
import { fetchEntities, fetchEntity } from 'actions/images'
import { generateSelectorsSingle } from './helpers'

const {
  getEntities,
  getEntity,
} = generateSelectorsSingle(IMAGE, { fetchEntities, fetchEntity })

export const getImages = getEntities
export const getImage = getEntity

*/


import { createSelector } from 'reselect'
import { getEntity } from '@fakundo/redux-entities'
import { IMAGE } from 'constants/entityNames'

export const getImage = createSelector(
  (state, params) => getEntity(state, IMAGE, params.uuid),
  result => result
)
