/* eslint-disable react/prop-types */
import React from 'react'
import Chip from '@material-ui/core/Chip'
import classNames from 'classnames'

export default function MultiValue({ children, selectProps, isFocused, removeProps }) {
  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={event => {
        removeProps.onClick()
        removeProps.onMouseDown(event)
      }}
    />
  )
}
