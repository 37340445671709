// This works for entities where all list is not same as single entity
import keyBy from 'lodash/keyBy'

import {
  replaceEntities,
  replaceCollections,
} from '@fakundo/redux-entities'

import {
  BATCHES,
  BATCH,
  IMAGE,
} from 'constants/entityNames'


const ALL_ENTITIES = BATCHES
const ENTITY = BATCH
const API_ENTITY = 'batch'
const API_ENTITY_LIST = '/list'

// Get all entities
export const fetchEntities = () =>
  (dispatch, getState, api) =>
    api({
      apiPath: `${API_ENTITY}/${API_ENTITY_LIST}`,
    })

// Reload all entities
export const reloadEntities = cancelToken =>
  async (dispatch) => {
    const data = await dispatch(fetchEntities(cancelToken))
    dispatch(replaceCollections({ [ALL_ENTITIES]: { ...keyBy(data, 'uuid') } }))
  }

export const fetchEntity = (entityUUID, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'get',
      apiPath: `${API_ENTITY}/${entityUUID}`,
      cancelToken
    })
    dispatch(replaceEntities(IMAGE, keyBy(entity.images, 'uuid')))
    return entity
  }

export const reloadEntity = (entityUUID, cancelToken) =>
  async (dispatch) => {
    const entity = await dispatch(fetchEntity(entityUUID, cancelToken))
    dispatch(replaceEntities(ENTITY, { [entityUUID]: entity }))
    dispatch(replaceEntities(ALL_ENTITIES, { [entityUUID]: entity }))
    // Fix to fill images
  }

export const updateEntity = (entityUUID, data, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'patch',
      apiPath: `${API_ENTITY}/${entityUUID}`,
      data,
      cancelToken
    })
    await dispatch(reloadEntity(entityUUID))
    return entity
  }
