import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const
  styles = {
    text: {
    },
  }

@withStyles(styles)
export default class EmptyListItem extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
  }

  render() {
    const { title, classes } = this.props
    return (
      <Fragment>
        <ListItem>
          <ListItemText
            className={classes.text}
            primary={title}
          />
        </ListItem>
        <Divider light />
      </Fragment>
    )
  }
}
