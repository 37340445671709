import axios, { isCancel } from 'axios'
import config from 'config'

const ApiError = function (message, code) {
  this.message = message
  this.code = code
}
ApiError.prototype = Error.prototype

export default (/* store */) => async ({ apiPath = '', withAuth = true, headers, ...rest }) => {
  const options = {
    url: config.apiURL + apiPath,
    withCredentials: withAuth,
    ...rest
  }

  try {
    const { data } = await axios(options)
    if (data.error) {
      throw new ApiError(data.error)
    }
    return data
  } catch (error) {
    if (isCancel(error)) {
      throw error
    }
    if (error.response && error.response.data && error.response.data.error) {
      const { text, code } = error.response.data.error
      throw new ApiError(text, code)
    }
    throw new ApiError(error.message)
  }
}
