import { ME_UPDATE, LOG_OUT } from 'actions/me'

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case ME_UPDATE: {
      return action.me
    }

    case LOG_OUT:
      return initialState

    default:
      return state
  }
}
