import { connect } from 'react-redux'
import withActions from 'react-axios-hoc'
import mapValues from 'lodash/mapValues'
import isFunction from 'lodash/isFunction'

export default (actions, options) => (wrappedComponent) => {
  const mapActionsToProps = (props) => {
    const { dispatch } = props
    return mapValues(
      isFunction(actions) ? actions(props) : actions,
      action => cancelToken => (...args) => dispatch(action(...args, cancelToken))
    )
  }
  return connect(null, null, null, options)(withActions(mapActionsToProps)(wrappedComponent))
}
