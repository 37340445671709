import * as batch from './batch'

const PATH = 'integrations/dropbox'

export const listPath = (path = '', cancelToken) =>
  (dispatch, getState, api) =>
    api({
      method: 'post',
      apiPath: `${PATH}/list`,
      data: {
        path,
      },
      cancelToken,
    })

export const downloadFolder = (data, cancelToken) =>
  async (dispatch, getState, api) => {
    const entity = await api({
      method: 'post',
      apiPath: `${PATH}/download-folder`,
      data,
      cancelToken,
    })
    await dispatch(batch.reloadEntities())
    return entity
  }
