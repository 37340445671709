// Misc
import { Component, createElement } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

// Atoms
import { Link as RouterLink } from 'react-router-dom'

// Styles
const styles = {
  root: {
    color: 'inherit',
    textDecoration: 'none',
  }
}

@withStyles(styles)
export default class Link extends Component {
  static propTypes = {
    external: PropTypes.bool,
    to: PropTypes.any,
    classes: PropTypes.object.isRequired,
    className: PropTypes.any,
  }

  render() {
    const { external, to, className, classes, ...rest } = this.props
    let
      element,
      props

    if (external) {
      element = 'a'
      props = { href: to, ...rest }
    } else {
      element = RouterLink
      props = { to, ...rest }
    }

    return createElement(element, {
      ...props,
      className: classNames(className, classes.root)
    })
  }
}
