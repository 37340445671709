import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Title from 'atoms/Title'
import EmptyListItem from 'atoms/EmptyListItem'
import Folder from './Folder'

export default class Folders extends Component {
  static propTypes = {
    folders: PropTypes.array.isRequired,
  }

  renderFolder = folder => <Folder key={folder.path} folder={folder} />

  render() {
    const { folders = [] } = this.props
    return (
      <Fragment>
        <Title title="Folders" />
        {
          !folders.length && <EmptyListItem title="No folders" />
        }
        {
          folders.map(this.renderFolder)
        }
      </Fragment>
    )
  }
}
