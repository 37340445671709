import { createReducer } from '@fakundo/redux-entities'
import { ME_UPDATE } from 'actions/me'
import * as entityNames from 'constants/entityNames'

const createCustomReducer = initialState => (state, action) => {
  switch (action.type) {
    // Reset entities when user has changed
    case ME_UPDATE: {
      // Do not reset entities when user actually is same
      const { me, prevMe } = action
      if (me && prevMe && me.uuid === prevMe.uuid) {
        return state
      }

      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}

export default createReducer(entityNames, createCustomReducer)
