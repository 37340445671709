/* eslint-disable react/prop-types */
import React from 'react'

export default function ValueContainer({ selectProps, children }) {
  return (
    <div className={selectProps.classes.valueContainer}>
      { children }
    </div>
  )
}
