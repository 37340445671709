// makeGetBatchForIntegrationPath
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connectGetters } from 'react-redux-getters'

import { withStyles } from '@material-ui/core/styles'
import connectActions from 'utils/connectActions'
import List from '@material-ui/core/List'
import Title from 'atoms/Title'

// Actions
import { listPath } from 'actions/cloud'
import { makeGetBatchForIntegrationPath } from 'selectors/batches'

import Folders from './Folders'
import Files from './Files'
import Path from './Path'

const
  styles = () => ({
    list: {
      background: '#fff',
    }
  }),
  mapActionsToProps = {
    listPathAction: listPath
  }

const mapGettersToProps = () => {
  const
    getBatch = makeGetBatchForIntegrationPath()

  return (state, { path, integrationUUID }) => ({
    batchGetter: getBatch(state, { path, integrationUUID }),
  })
}

@connectGetters(mapGettersToProps)
@connectActions(mapActionsToProps)
@withStyles(styles)
export default class DropboxListPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    folderData: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    // integrationUUID: PropTypes.string.isRequired,
    batchGetter: PropTypes.object.isRequired,
  }

  render() {
    const { folderData, path, classes, batchGetter } = this.props

    const batchUUID = (batchGetter.data && !!batchGetter.data.length) ? batchGetter.data[0].uuid : ''

    return (
      <Fragment>
        <div>
          <List className={classes.list}>
            <Title title="Dropbox" />
            <Path path={path} batchUUID={batchUUID} />
            <Folders folders={folderData.folders} />
            <Files files={folderData.files} />
          </List>
        </div>
      </Fragment>
    )
  }
}
