import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'

import Option from './Components/Option'
import Control from './Components/Control'
import NoOptionsMessage from './Components/NoOptionsMessage'
import Placeholder from './Components/Placeholder'
import SingleValue from './Components/SingleValue'
import MultiValue from './Components/MultiValue'
import ValueContainer from './Components/ValueContainer'
import Menu from './Components/Menu'

import styles from './styles'

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
  Menu,
}

@withStyles(styles, { withTheme: true })
export default class DropDown extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }

  handleChange = value => {
    const { onChange } = this.props
    onChange(value)
  }

  render() {
    const { classes, theme, placeholder, options, value } = this.props

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
      }),
    }

    return (
      <div className={classes.root}>
        <Select
          classes={classes}
          styles={selectStyles}
          options={options}
          components={components}
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder || 'Search'}
        />
      </div>
    )
  }
}
