import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connectGetters } from 'react-redux-getters'

// Atoms
import ProgressBarOnTop from 'atoms/ProgressBar/ProgressBarOnTop'
import Batch from 'components/Batch'

// Actions
import { getBatch } from 'selectors/batches'

const
  mapGettersToProps = (state, { match: { params: { batchUUID } } }) => ({
    batchGetter: getBatch(state, { uuid: batchUUID }),
  })

@connectGetters(mapGettersToProps)
export default class DataPoint extends Component {
  static propTypes = {
    batchGetter: PropTypes.object.isRequired,
  }

  render() {
    const
      { batchGetter } = this.props,
      batch = batchGetter.data

    if (!batchGetter.isSucceded) {
      return <ProgressBarOnTop />
    }

    return <Batch batch={batch} />
  }
}
