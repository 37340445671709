import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'
import connectActions from 'utils/connectActions'
import map from 'lodash/map'

// Components
import Dialog from 'components/Dialog'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'

import Divider from '@material-ui/core/Divider'
import Spacer from 'atoms/Spacer'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import DropDown from 'components/DropDown'

// Selectors
import { makeGetBatchGlobal } from 'selectors/batches'
import { makeGetLocationsChain } from 'selectors/locations'

// Actions
import { updateEntity } from 'actions/batch'

// Styles
const styles = (theme) => ({
  root: {
    width: 600,
    maxWidth: '100%',
  },
  parents: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row'
  },
  parentIcon: {
    color: 'rgba(0,0,0,0.5)',
    lineHeight: '1.5rem',
    float: 'left',
  },
  parentArrow: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
  }
})


const mapGettersToProps = () => {
  const
    getBatch = makeGetBatchGlobal(),
    getLocations = makeGetLocationsChain()

  return (state, { batchUUID }) => ({
    batchGetter: getBatch(state, { uuid: batchUUID }),
    locationsGetter: getLocations(state)
  })
}

const
  mapActionsToProps = {
    updateBatchAction: updateEntity
  }

@connectActions(mapActionsToProps)
@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class EditBatchDialog extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    batchGetter: PropTypes.object.isRequired,
    locationsGetter: PropTypes.object.isRequired,
    updateBatchAction: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    batchUUID: PropTypes.string.isRequired,
  }

  state = {
    caption: undefined,
    locationUUID: undefined,
    errorOpen: false,
    errorMessage: '',
  }

  onErrorClose = () => {
    this.setState({
      errorOpen: false
    })
  }

  handleChangeCaption = (event) => {
    const { value } = event.target
    this.setState({
      caption: value,
      errorOpen: false,
      errorMessage: '',
    })
  }

  handleUpdateBatch = async () => {
    const { batchUUID, batchGetter, updateBatchAction, onClose } = this.props
    const { caption, locationUUID } = this.state

    try {
      await updateBatchAction.run({
        params: [
          batchUUID,
          {
            caption: caption || batchGetter.data.caption,
            locationUUID: locationUUID || batchGetter.data.locationUUID,
          },
        ],
      })
      onClose()
      this.setState({
        caption: undefined,
        locationUUID: undefined,
      })
    } catch (error) {
      this.setState({
        errorOpen: true,
        errorMessage: error.message,
      })
    }
  }

  onLocationChange = (value) => {
    this.setState({
      locationUUID: value.uuid,
    })
  }

  renderButtons = () => {
    const { classes, onClose } = this.props
    return (
      <Fragment>
        <Button color="primary" className={classes.button} onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" className={classes.button} onClick={this.handleUpdateBatch}>
          Confirm
        </Button>
      </Fragment>
    )
  }

  preventEvent = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  // TODO: COMMON LOCATION DROPDOWN COMPONENT
  renderDropDown = () => {
    const
      { locationUUID } = this.state,
      { locationsGetter, batchGetter } = this.props,
      locations = locationsGetter.data,
      selectedLocation = locations[locationUUID || batchGetter.data.locationUUID],
      value = selectedLocation ? {
        value: selectedLocation.uuid,
        label: selectedLocation.name,
        caption: selectedLocation.parents.slice(1).map(({ name }) => name).join(' ╱ '),
      } : null

    const options = map(locations, location => {
      const path = location.parents.slice(1).map(({ name }) => name).join(' ╱ ')
      return {
        value: path,
        uuid: location.uuid,
        label: location.name,
        caption: path,
      }
    })

    return <DropDown value={value} options={options} onChange={this.onLocationChange} />
  }

  render() {
    const { classes } = this.props
    const { batchGetter, locationsGetter, updateBatchAction, open, onClose } = this.props
    const { caption, errorOpen, errorMessage } = this.state

    if (!batchGetter.isSucceded || !locationsGetter.isSucceded) {
      return false
    }

    const captionValue = (caption === undefined ? batchGetter.data.caption : caption) || ''

    return (
      <div onClick={this.preventEvent}>
        <Dialog scroll="body" title="Update data point" open={open} onClose={onClose} buttons={!updateBatchAction.isPending && this.renderButtons()}>
          <div className={classes.root}>
            {
              this.renderDropDown()
            }
            <Divider light />
            <Spacer small />
            <FormControl className={classes.root} error={errorOpen}>
              <InputLabel>
                Caption
              </InputLabel>
              <Input value={captionValue} onChange={this.handleChangeCaption} />
              <FormHelperText id="name-error-text">
                { errorMessage }
              </FormHelperText>
            </FormControl>
            {
              updateBatchAction.isPending && (
                <Fragment>
                  <Spacer small />
                  <CircularProgress />
                </Fragment>
              )
            }
          </div>
        </Dialog>
      </div>
    )
  }
}
