import React from 'react'
import ReactDOM from 'react-dom'
import createHistory from 'history/createBrowserHistory'
import { createTracker } from 'redux-segment'
import configureStore from '../src/configureStore'
import App from '../src/App'

const history = createHistory()
const tracker = createTracker()
const store = configureStore(history, tracker)

ReactDOM.render(
  <App {...{ store, history }} />,
  document.getElementById('root')
)
