import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connectGetters } from 'react-redux-getters'
import find from 'lodash/find'

import connectActions from 'utils/connectActions'
import { getMe } from 'selectors/me'

// Atoms
import ProgressBarOnTop from 'atoms/ProgressBar/ProgressBarOnTop'

// Actions
import { listPath } from 'actions/cloud'

import List from './List'

const DROPBOX = 'DROPBOX'

const
  mapActionsToProps = {
    listPathAction: listPath
  }

const mapGettersToProps = () => (state) => ({
  meGetter: getMe(state),
})

@connectGetters(mapGettersToProps)
@connectActions(mapActionsToProps)
export default class DropboxListPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    listPathAction: PropTypes.object.isRequired,
    meGetter: PropTypes.object.isRequired,
  }

  state = {
    folderData: false,
    path: false,
  }

  componentDidMount = async () => {
    this.loadPath()
  }

  async componentDidUpdate(prevProps) {
    const { location } = this.props
    if (prevProps.location.pathname !== location.pathname) {
      this.loadPath()
    }
  }

  loadPath = async () => {
    const
      { location, match, listPathAction } = this.props,
      path = location.pathname.split(match.path)[1].trim().replace(/\/\//g, '/'),
      folderData = await listPathAction.run({
        params: [path === '/' ? '' : path],
      })

    this.setState({
      folderData,
      path,
    })
  }

  render() {
    const
      { listPathAction, meGetter } = this.props,
      { folderData, path } = this.state

    if (listPathAction.isPending || !folderData || meGetter.isPending) {
      return <ProgressBarOnTop />
    }

    const integration = find(meGetter.data.integrations,
      entity => entity.serviceName === DROPBOX)

    return <List folderData={folderData} path={path} integrationUUID={integration.uuid || ''} />
  }
}
