import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import connectActions from 'utils/connectActions'

// Atoms
import Link from 'atoms/Link'
import ListItem from '@material-ui/core/ListItem'

// Elements
import Button from '@material-ui/core/Button'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DoneIcon from '@material-ui/icons/Done'
import NewBatchDialog from 'components/Dialogs/NewBatchDialog'

// Actions
import { listPath } from 'actions/cloud'

const
  styles = (theme) => ({
    root: {
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: theme.spacing.unit / 2,
    },
    sharedIconImage: {
      fontSize: 19,
    },
    button: {
      marginBottom: theme.spacing.unit,
      marginRight: 0,
    },
    folderSplitter: {
      padding: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
  }),
  mapActionsToProps = {
    listPathAction: listPath
  }

@connectActions(mapActionsToProps)
@withStyles(styles)
export default class Path extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    batchUUID: PropTypes.string,
  }


  state = {
    dialogOpen: false,
  }

  openDialog = () => {
    this.setState({
      dialogOpen: true
    })
  }

  closeDialog = () => {
    this.setState({
      dialogOpen: false
    })
  }

  renderPathLink = ({ path, name, only }, id) => {
    const { classes } = this.props
    return (
      <Fragment key={`${path}${id}`}>
        {' '}
        <Link to={`/dropbox/list${path}`} key={path}>
          <Button variant="outlined" color="primary" className={classes.button}>
            {
              !!name && name
            }
            {
              !name && <FolderSharedIcon className={classes.sharedIconImage} />
            }
          </Button>
        </Link>
        { !only && (
          <div className={classes.folderSplitter}>
            ╱
          </div>
        )}
      </Fragment>
    )
  }

  render() {
    const { path, classes, batchUUID } = this.props
    const { dialogOpen } = this.state

    if (path === false) {
      return false
    }

    const elements = ((path === '/' || path === '//') ? '' : path).split('/')
    let pathNow = ''
    const linksList = []
    let lastLink

    for (let i = 0; i < elements.length; i += 1) {
      if (i) {
        pathNow += '/'
      }
      pathNow += `${elements[i]}`
      const link = {
        path: pathNow,
        name: elements[i],
        only: elements.length === 1,
        last: i + 1 === elements.length,
      }

      if (i !== elements.length - 1) {
        linksList.push(link)
      } else if (elements.length > 1) {
        lastLink = link
      }
    }
    return (
      <Fragment>
        <NewBatchDialog
          open={dialogOpen}
          onClose={this.closeDialog}
          path={pathNow}
        />
        <ListItem className={classes.root}>
          { linksList.map(this.renderPathLink) }
          { lastLink && !batchUUID && (
            <Button variant="contained" color="primary" className={classes.button} onClick={this.openDialog}>
              <CloudDownloadIcon className={classes.sharedIconImage} />
              &nbsp;
              {
                lastLink.name
              }
            </Button>
          )}
          {
            !!batchUUID && (
              <Link to={`/datapoint/${batchUUID}`}>
                <Button variant="contained" className={classes.button}>
                  <DoneIcon className={classes.sharedIconImage} />
                  &nbsp;
                  Downloaded
                </Button>
              </Link>
            )
          }
        </ListItem>
      </Fragment>
    )
  }
}
