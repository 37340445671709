import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Elements
import Title from 'atoms/Title'
import EmptyListItem from 'atoms/EmptyListItem'

import File from './File'

export default class Folders extends Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
  }

  renderFile = file => <File key={file.path} file={file} />

  render() {
    const { files = [] } = this.props
    return (
      <Fragment>
        <Title title="Files" />
        {
          !files.length && <EmptyListItem title="No files" />
        }
        {
          files.map(this.renderFile)
        }
      </Fragment>
    )
  }
}
