import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import values from 'lodash/values'

import { connectGetters } from 'react-redux-getters'

// Atoms
import ProgressBarOnTop from 'atoms/ProgressBar/ProgressBarOnTop'
import BatchPreviewLink from 'components/BatchPreviewLink'
import Title from 'atoms/Title'
import EmptyListItem from 'atoms/EmptyListItem'

// Actions
import { makeGetBatchesForUUIDS } from 'selectors/batches'

const
  mapGettersToProps = (state, { batchUUIDList }) => {
    const getBatches = makeGetBatchesForUUIDS()
    return ({
      batchesGetter: getBatches(state, { uuids: batchUUIDList }),
    })
  }

@connectGetters(mapGettersToProps)
export default class DataPointList extends Component {
  static propTypes = {
    batchesGetter: PropTypes.object.isRequired,
    title: PropTypes.any,
    secondaryTitle: PropTypes.any,
    emptyTitle: PropTypes.any,
    locationUUID: PropTypes.string,
  }

  filterBathes = () => {
    const { batchesGetter: { data }, locationUUID } = this.props
    if (!locationUUID) {
      return {
        primary: values(data),
        secodnary: false,
      }
    }
    return {
      primary: filter(data, dataPoint => dataPoint.locationUUID === locationUUID),
      secondary: filter(data, dataPoint => dataPoint.locationUUID !== locationUUID),
    }
  }

  renderBatchPreview = batch => <BatchPreviewLink key={batch.uuid} batch={batch} />

  render() {
    const
      { batchesGetter, title, secondaryTitle, emptyTitle } = this.props

    if (!batchesGetter.isSucceded) {
      return <ProgressBarOnTop />
    }

    const { primary, secondary } = this.filterBathes(batchesGetter.data)

    return (
      <Fragment>
        {
          !!title && <Title title={title} />
        }
        {
          !primary.length && <EmptyListItem title={emptyTitle} />
        }
        {
          primary.map(this.renderBatchPreview)
        }
        {
          !!secondary && !!secondary.length && !!secondaryTitle && <Title title={secondaryTitle} />
        }
        {
          !!secondary && secondary.map(this.renderBatchPreview)
        }
      </Fragment>
    )
  }
}
