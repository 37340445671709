export const
  ME_UPDATE = 'ME_UPDATE',
  LOG_OUT = 'LOG_OUT',

  fetchMe = () =>
    (dispatch, getState, api) =>
      api({ apiPath: 'user' }),

  updateMe = me =>
    (dispatch, getState) => {
      const { me: prevMe } = getState()
      dispatch({ type: ME_UPDATE, me, prevMe })
    },

  refetchMe = () =>
    async (dispatch) => {
      const me = await dispatch(fetchMe())
      dispatch(updateMe(me))
    },

  logOut = cancelToken =>
    async (dispatch, getState, api) => {
      await api({
        method: 'post',
        apiPath: 'logout',
        cancelToken,
      })
      dispatch({ type: LOG_OUT })
    },

  patchMe = (data, cancelToken) =>
    async (dispatch, getState, api) => {
      const nextMe = await api({
        method: 'patch',
        apiPath: 'user',
        data,
        cancelToken
      })
      dispatch(updateMe(nextMe))
    }
