export default function (theme) {
  return {
    card: {
      width: '100%',
      minHeight: 324,
    },
    media: {
      marginBottom: theme.spacing.unit,
      width: '100%',
      height: 240,
      objectFit: 'contain',
      cursor: 'pointer',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }
}
