import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'

import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import PersonIcon from '@material-ui/icons/Person'
import CloudIcon from '@material-ui/icons/Cloud'
import ImageIcon from '@material-ui/icons/Language'
import ArchiveIcon from '@material-ui/icons/Archive'
import SvgIcon from '@material-ui/core/SvgIcon'
import faviconSvg from 'assets/favicon.svg'
import Link from 'atoms/Link'

// Selectors
import { getMe } from 'selectors/me'

const
  styles = {
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 180,
    },
    icon: {
      width: 120,
      height: 120,
    }
  },
  mapGettersToProps = state => ({
    meGetter: getMe(state)
  })


// this.toggleDrawer('left', true)

@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class MainDrawer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
    // meGetter: PropTypes.object.isRequired,
  }


  render() {
    const { classes, open, onClose } = this.props

    return (
      <Drawer open={open} onClose={onClose}>
        <div
          tabIndex={0}
          role="button"
          onClick={onClose}
          onKeyDown={onClose}
        >
          <div className={classes.list}>
            <List>
              <ListItem className={classes.iconWrapper}>
                <SvgIcon className={classes.icon}>
                  <use xlinkHref={`#${faviconSvg.id}`} />
                </SvgIcon>
              </ListItem>
              <Divider />
              <Link to="/location">
                <ListItem button>
                  <ListItemIcon>
                    <ImageIcon />
                  </ListItemIcon>
                  <ListItemText primary="Locations" />
                </ListItem>
              </Link>

              <Link to="/datapoint">
                <ListItem button>
                  <ListItemIcon>
                    <ArchiveIcon />
                  </ListItemIcon>
                  <ListItemText primary="Data points" />
                </ListItem>
              </Link>

              <Link to="/profile">
                <ListItem button>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </ListItem>
              </Link>

              <Link to="/dropbox/list">
                <ListItem button>
                  <ListItemIcon>
                    <CloudIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dropbox" />
                </ListItem>
              </Link>

              <Divider />
            </List>
          </div>
        </div>
      </Drawer>
    )
  }
}
