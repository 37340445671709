import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

// Styles
const styles = () => ({ })

@withStyles(styles)
export default class DialogComponent extends Component {
  static propTypes = {
    // classes: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
    className: PropTypes.any,
    buttons: PropTypes.any,
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    scroll: PropTypes.string,
  }

  render() {
    const { children, buttons, title, open, onClose, className, scroll } = this.props
    return (
      <Dialog
        className={className}
        open={open}
        scroll={scroll || 'paper'}
        onClose={onClose}
      >
        { !!title && (
          <DialogTitle>
            {title}
          </DialogTitle>
        )}
        <DialogContent>
          { children }
        </DialogContent>
        { !!buttons && (
          <DialogActions>
            { buttons }
          </DialogActions>
        )}
      </Dialog>
    )
  }
}
