import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import connectActions from 'utils/connectActions'

// Selectors
import { getImage } from 'selectors/images'

// Actions
import { addTag, removeTag } from 'actions/images'

// Atoms
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import ImageDialog from 'components/ImageDialog'

import TagsMenu from './TagsMenu'
// import Dummy from './Dummy'
import styles from './styles'

const makeThumbnailUrl = image => {
  if (!image) {
    return ''
  }
  const
    elements = image.split('/'),
    file = elements.pop()
  elements.push('thumbnail', file)
  return elements.join('/')
}

const
  mapActionsToProps = {
    addTagAction: addTag,
    removeTagAction: removeTag,
  }

const mapStateToProps = (state, { imageUUID }) => ({
  image: getImage(state, { uuid: imageUUID })
})

export default
@connect(mapStateToProps)
@connectActions(mapActionsToProps)
@withStyles(styles)
class ImageThumbnail extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
  }

  state = {
    dialogOpen: false,
  }

  openDialog = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      dialogOpen: true
    })
  }

  closeDialog = () => {
    this.setState({
      dialogOpen: false
    })
  }

  render() {
    const
      { dialogOpen } = this.state,
      { classes, image } = this.props

    if (!image) {
      return 'Nope'
    }

    return (
      <Fragment>
        <ImageDialog
          image={image}
          open={dialogOpen}
          onClose={this.closeDialog}
        />
        <Card className={classes.card}>
          <Fragment>
            <CardHeader
              className={classes.ellipsis}
              action={<TagsMenu image={image} />}
              subheader={<span>{image.name}</span>}
            />
          </Fragment>
          <CardMedia
            id={image.uuid}
            component="img"
            className={classes.media}
            height="240"
            onClick={this.openDialog}
            image={makeThumbnailUrl(image.url)}
            title={image.name}
          />
        </Card>
      </Fragment>
    )
  }
}
