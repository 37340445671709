import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
// import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'

// Atoms
import ProgressBarOnTop from 'atoms/ProgressBar/ProgressBarOnTop'

// Actions
import { /* getLocations,  */ makeGetRoot } from 'selectors/locations'

import Location from './Location'

const mapGettersToProps = state => ({
  // locationsGetter: getLocations(state),
  locationsRootGetter: makeGetRoot()(state),
})

@connectGetters(mapGettersToProps)
// @withStyles(styles)
export default class LocationPage extends Component {
  static propTypes = {
    // classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    locationsRootGetter: PropTypes.object.isRequired,
  }

  render() {
    const
      { locationsRootGetter, match } = this.props,
      { locationUUID } = match.params

    if (!locationsRootGetter.isSucceded) {
      return <ProgressBarOnTop />
    }

    const location = locationUUID || locationsRootGetter.data.uuid

    return (
      <Fragment>
        <Location uuid={location} />
      </Fragment>
    )
  }
}
