import React from 'react'
import PropTypes from 'prop-types'
import Link from 'atoms/Link'

import BatchPreview from 'components/BatchPreview'

const BatchPreviewLink = ({ batch }) => (
  <Link to={`/datapoint/${batch.uuid}`}>
    <BatchPreview key={batch.uuid} batch={batch} button />
  </Link>
)

BatchPreviewLink.propTypes = {
  batch: PropTypes.object.isRequired,
}

export default BatchPreviewLink
