import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'
import cloneDeep from 'lodash/cloneDeep'

export const parseTags = (tags, tagsList, me) => {
  const
    meUUID = me.uuid,
    tagsEntities = keyBy(cloneDeep(tags), 'uuid'),
    specialTags = {}

  tagsList.forEach(({ tagUUID, userUUID }) => {
    const isCreated = meUUID === userUUID
    tagsEntities[tagUUID].users = tagsEntities[tagUUID].users || []
    tagsEntities[tagUUID].users.push(userUUID)
    tagsEntities[tagUUID].created = tagsEntities[tagUUID].created || isCreated
  })

  tagsList.forEach(({ tagUUID }) => {
    if (tagsEntities[tagUUID].name[0] !== '_') {
      return
    }
    specialTags[tagsEntities[tagUUID].name] = tagsEntities[tagUUID]
  })

  return {
    tags: filter(tagsEntities, ({ name }) => name[0] !== '_'),
    special: specialTags,
  }
}
