// Misc
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Switch from 'react-router-transition-group'
import Fade from '@material-ui/core/Fade'
import UserLayout from 'components/Layout/UserLayout'

import DropboxListPage from './Dropbox'
import ProfilePage from './Profile'
import LocationPage from './Location'
import DataPointListPage from './DataPointList'
import DataPointPage from './DataPoint'

export default () => (
  <UserLayout>
    <Switch transition={Fade}>
      <Redirect exact from="/" to="/location" />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/dropbox/list" component={DropboxListPage} />

      <Route exact path="/location" component={LocationPage} />
      <Route path="/location/:locationUUID" component={LocationPage} />

      <Route exact path="/datapoint" component={DataPointListPage} />
      <Route path="/datapoint/:batchUUID" component={DataPointPage} />
    </Switch>
  </UserLayout>
)
