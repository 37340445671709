/* eslint-disable react/prop-types */
import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function SingleValue({ selectProps, innerProps, children }) {
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      { children }
    </Typography>
  )
}
