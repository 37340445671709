import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Provider as StoreProvider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { withRouter, Route } from 'react-router'
import CssBaseline from '@material-ui/core/CssBaseline'
import Nav from 'components/Nav'

import Routes from './pages'

// require('utils/i18next')

@withRouter
class ScrollToTop extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.any
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    if (location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

export default function App({ store, history }) {
  return (
    <StoreProvider store={store}>
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <Fragment>
            <CssBaseline />
            <Nav />
            <Route component={Routes} />
          </Fragment>
        </ScrollToTop>
      </ConnectedRouter>
    </StoreProvider>
  )
}

App.propTypes = {
  store: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
}
