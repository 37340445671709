import React, { Component } from 'react'
import { connectGetters } from 'react-redux-getters'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import AddTagDialog from 'components/Dialogs/AddTag'

// Selectors
import { getMe } from 'selectors/me'

import Tags from './Tags'

// Styles
const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing.unit,
  },
})

const mapGettersToProps = () => state => ({
  meGetter: getMe(state),
})


@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class ExtendedInfo extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    meGetter: PropTypes.object.isRequired,
  }

  state = {
    dialogOpen: false,
  }

  openDialog = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      dialogOpen: true
    })
  }

  closeDialog = () => {
    this.setState({
      dialogOpen: false
    })
  }

  render() {
    const
      { classes, image, meGetter } = this.props,
      { dialogOpen } = this.state

    if (meGetter.isPending) {
      return <CircularProgress />
    }

    return (
      <div className={classes.root}>
        <AddTagDialog
          open={dialogOpen}
          onClose={this.closeDialog}
          image={image}
        />
        <Tags image={image} user={meGetter.data} />
        <Button color="primary" className={classes.button} onClick={this.openDialog}>
          Add tag
        </Button>
      </div>
    )
  }
}
