import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'
import connectActions from 'utils/connectActions'

import { parseTags } from 'utils/tags'

// Selectors
import { getMe } from 'selectors/me'

// Actions
import { addTag, removeTag } from 'actions/images'

// Atoms
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import SyncIcon from '@material-ui/icons/Sync'
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'

const hasOwnTag = (tagData, tagName) => tagData[tagName] && tagData[tagName].created

const
  _FAVORITE = '_FAVORITE',
  _TYPE_OBJECT = '_TYPE_OBJECT',
  _TYPE_MAYBE_TECH = '_TYPE_MAYBE_TECH',
  _TYPE_TECH = '_TYPE_TECH',
  _TYPE_NATURE = '_TYPE_NATURE',

  _TYPE_INFORMATION = '_TYPE_INFORMATION',
  _FLAG_TRASH = '_FLAG_TRASH',
  _FLAG_LQ = '_FLAG_LQ',
  _FLAG_IRRELEVANT = '_FLAG_IRRELEVANT',
  _FLAG_WRONG_OBJECT = '_FLAG_WRONG_OBJECT'

const
  TAG_CAPTIONS = {
    [_TYPE_OBJECT]: 'Object / Subject',
    [_TYPE_MAYBE_TECH]: 'Possibly tech',
    [_TYPE_TECH]: 'High tech',
    [_TYPE_INFORMATION]: 'Info / Sign',
    [_TYPE_NATURE]: 'Nature only',
    [_FLAG_TRASH]: 'Trash',
    [_FLAG_LQ]: 'Low quality',
    [_FLAG_IRRELEVANT]: 'Irrelevant',
    [_TYPE_NATURE]: 'Nature only',
    [_FLAG_WRONG_OBJECT]: 'Wrong object',
  },
  TAG_TYPE = [
    _TYPE_OBJECT,
    _TYPE_MAYBE_TECH,
    _TYPE_TECH,
    _TYPE_INFORMATION,
    _TYPE_NATURE,
  ],
  TAG_FLAG = [
    _FLAG_TRASH,
    _FLAG_LQ,
    _FLAG_IRRELEVANT,
    _FLAG_WRONG_OBJECT,
  ]

const
  styles = () => {
    return {
      starBorderIcon: {
        '&:hover': {
          color: 'gold',
        },
      },
      starIcon: {
        '&:not(:hover)': {
          color: 'gold',
        },
      },
      syncIcon: {
        opacity: 0.1,
      },
      menuItemLoading: {
        opacity: 0.5,
      },
      menuLoader: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
      }
    }
  }

const mapGettersToProps = () => state => ({
  meGetter: getMe(state),
})

const
  mapActionsToProps = {
    addTagAction: addTag,
    removeTagAction: removeTag,
  }

@connectActions(mapActionsToProps)
@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class TagMenu extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    meGetter: PropTypes.object.isRequired,
    addTagAction: PropTypes.object.isRequired,
    removeTagAction: PropTypes.object.isRequired,
  }

  state = {
    menuRef: false,
    favoriteLoading: false,
  }

  toggleFavorite = async () => {
    const
      { addTagAction, removeTagAction, image } = this.props,
      hasOwnFavorite = hasOwnTag(this.tagData(), _FAVORITE),
      action = hasOwnFavorite ? removeTagAction : addTagAction

    this.setState({
      favoriteLoading: true,
    })

    await action.run({
      params: [
        image.uuid,
        _FAVORITE,
      ],
    })

    this.setState({
      favoriteLoading: false,
    })
  }

  toggleTag = async (tagName, value) => {
    const
      { addTagAction, removeTagAction, image } = this.props,
      action = value ? removeTagAction : addTagAction

    await action.run({
      params: [
        image.uuid,
        tagName,
      ],
    })
  }

  tagData = () => {
    const { meGetter, addTagAction, removeTagAction, image } = this.props

    if (meGetter.isPending) {
      return false
    }

    const
      { tags, tagsList } = image,
      me = meGetter.data
    return {
      ...parseTags(tags, tagsList, me).special,
      loading: addTagAction.isPending || removeTagAction.isPending,
    }
  }

  setMenuRef = event => this.setState({ menuRef: event.currentTarget })

  cleanMenuRef = () => this.setState({ menuRef: false })

  renderMenuItem = (tag, loading) => {
    const
      { classes } = this.props,
      { users = [], name, created } = tag,
      count = (users || []).length
    return (
      <MenuItem
        key={name}
        onClick={!loading ? () => this.toggleTag(name, created) : undefined}
        selected={created}
        className={loading ? classes.menuItemLoading : classes.menuItem}
      >
        {
          TAG_CAPTIONS[name]
        }
        {
          count !== 0 && ` (${count})`
        }
      </MenuItem>
    )
  }

  renderMenu = (tagData) => {
    if (!tagData) {
      return false
    }
    const
      { classes } = this.props,
      { menuRef } = this.state,
      { loading } = tagData

    return (
      <Menu
        anchorEl={menuRef || null}
        open={Boolean(menuRef)}
        onClose={this.cleanMenuRef}
      >
        {' '}
        {
          loading && (
            <LinearProgress className={classes.menuLoader} />
          )
        }
        {
          TAG_TYPE.map(tagName =>
            this.renderMenuItem(tagData[tagName] || { name: tagName }, loading))
        }
        <Divider />
        {
          TAG_FLAG.map(tagName =>
            this.renderMenuItem(tagData[tagName] || { name: tagName }, loading))
        }
      </Menu>
    )
  }

  renderFavorite = tagData => {
    const
      { favoriteLoading } = this.state,
      { classes } = this.props,
      hasOwnFavorite = hasOwnTag(tagData, _FAVORITE)

    const
      showLoading = favoriteLoading || !tagData,
      iconButtonClass = hasOwnFavorite ? classes.starIcon : classes.starBorderIcon


    return (
      <Fragment>
        {
          showLoading && (
            <IconButton className={classes.syncIcon}>
              <SyncIcon />
            </IconButton>
          )
        }
        {
          !showLoading && (
            <IconButton className={iconButtonClass} onClick={this.toggleFavorite}>
              {
                hasOwnFavorite ? <StarIcon /> : <StarBorderIcon />
              }
            </IconButton>
          )
        }
      </Fragment>
    )
  }

  render = () => {
    const tagData = this.tagData()

    return (
      <Fragment>
        {
          this.renderMenu(tagData)
        }
        {
          this.renderFavorite(tagData)
        }
        <IconButton onClick={this.setMenuRef}>
          <MoreVertIcon />
        </IconButton>
      </Fragment>
    )
  }
}
