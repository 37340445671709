import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

// Components
import ImageListBatch from 'components/ImageList/Batch'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import BatchPreview from 'components/BatchPreview'
import EditBatchDialog from 'components/Dialogs/EditBatchDialog'

const
  styles = (theme) => ({
    fileIcon: {
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
    fileIconImage: {
      width: theme.spacing.unit * 2,
      height: theme.spacing.unit * 2,
    },
    editButton: {
      marginRight: theme.spacing.unit * 2
    },
  })

@withStyles(styles)
export default class Batch extends Component {
  static propTypes = {
    batch: PropTypes.object.isRequired,
  }

  state = {
    dialogOpen: false,
  }

  openDialog = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      dialogOpen: true
    })
  }

  closeDialog = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      dialogOpen: false
    })
  }


  render() {
    const { batch } = this.props
    const { dialogOpen } = this.state

    return (
      <Fragment>
        <EditBatchDialog
          open={dialogOpen}
          onClose={this.closeDialog}
          batchUUID={batch.uuid}
        />
        <List>
          <BatchPreview key={batch.uuid} batch={batch} />
          <ListItem>
            <ImageListBatch batchUUID={batch.uuid} />
          </ListItem>
        </List>
      </Fragment>
    )
  }
}
