import React, { Component } from 'react'
import connectActions from 'utils/connectActions'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

import StarsIcon from '@material-ui/icons/Stars'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
// import Link from 'atoms/Link'
// import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'

// Actions
import { addTag, removeTag } from 'actions/images'

// Styles
const styles = (theme) => ({
  root: {
  },
  chip: {
    marginRight: theme.spacing.unit,
  },
  chipWorking: {
    marginRight: theme.spacing.unit,
    opacity: 0.8,
  }
})

const
  mapActionsToProps = {
    addTagAction: addTag,
    removeTagAction: removeTag,
  }

@connectActions(mapActionsToProps)
@withStyles(styles)
export default class ExtendedInfo extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    tag: PropTypes.object.isRequired,
    imageUUID: PropTypes.string.isRequired,
    addTagAction: PropTypes.object.isRequired,
    removeTagAction: PropTypes.object.isRequired,
  }

  handleAdd = async () => {
    const { addTagAction, tag, imageUUID } = this.props

    await addTagAction.run({
      params: [
        imageUUID,
        tag.name,
      ],
    })
  }

  handleDelete = async () => {
    const { removeTagAction, tag, imageUUID } = this.props
    await removeTagAction.run({
      params: [
        imageUUID,
        tag.name,
      ],
    })
  }

  render() {
    const
      { classes, tag, addTagAction, removeTagAction } = this.props,
      { name, created } = tag,
      handleDelete = created ? this.handleDelete : this.handleAdd,
      color = created ? 'primary' : undefined

    if (addTagAction.isPending || removeTagAction.isPending) {
      return (
        <Chip
          variant="outlined"
          label={name}
          clickable={false}
          className={classes.chipWorking}
          deleteIcon={<MoreHorizIcon />}
          onDelete={() => 1}
          color={color}
        />
      )
    }

    return (
      <Chip
        variant="outlined"
        label={name}
        className={classes.chip}
        deleteIcon={created ? undefined : <StarsIcon />}
        onDelete={handleDelete}
        color={color}
      />
    )
  }
}
