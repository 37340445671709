import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'
import connectActions from 'utils/connectActions'

// Components
import Dialog from 'components/Dialog'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Spacer from 'atoms/Spacer'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
// import Snackbar from '@material-ui/core/Snackbar'

// Selectors
import { makeGetLocationChain } from 'selectors/locations'

// Actions
import { addTag } from 'actions/images'

// Styles
const styles = (theme) => ({
  root: {
    width: 600,
    maxWidth: '100%',
  },
  parents: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row'
  },
  parentIcon: {
    color: 'rgba(0,0,0,0.5)',
    lineHeight: '1.5rem',
    float: 'left',
  },
  parentArrow: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
  }
})


const mapGettersToProps = () => {
  const getLocation = makeGetLocationChain()
  return (state, { parentUUID }) => ({
    locationGetter: getLocation(state, { uuid: parentUUID }),
  })
}

const
  mapActionsToProps = {
    addTagAction: addTag
  }

@connectActions(mapActionsToProps)
@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class NewLocationDialog extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    addTagAction: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    name: '',
    errorOpen: false,
    errorMessage: '',
  }

  onErrorClose = () => {
    this.setState({
      errorOpen: false
    })
  }

  handleChangeName = (event) => {
    const { value } = event.target
    this.setState({
      name: value,
      errorOpen: false,
      errorMessage: '',
    })
  }

  handleAddTag = async () => {
    const { image, addTagAction, onClose } = this.props
    const { name } = this.state

    try {
      await addTagAction.run({
        params: [
          image.uuid,
          name,
        ],
      })
      onClose()
      this.setState({
        name: '',
      })
    } catch (error) {
      this.setState({
        errorOpen: true,
        errorMessage: error.message,
      })
    }
  }

  renderButtons = () => {
    const { classes, onClose } = this.props
    return (
      <Fragment>
        <Button color="primary" className={classes.button} onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" className={classes.button} onClick={this.handleAddTag}>
          Add
        </Button>
      </Fragment>
    )
  }

  render() {
    const
      { classes, addTagAction, open, onClose } = this.props,
      { name, errorOpen, errorMessage } = this.state

    return (
      <Fragment>
        <Dialog title="Add tag" open={open} onClose={onClose} buttons={!addTagAction.isPending && this.renderButtons()}>
          <div className={classes.root}>
            <FormControl className={classes.root} error={errorOpen}>
              <InputLabel>
                Tag
              </InputLabel>
              <Input value={name} onChange={this.handleChangeName} />
              <FormHelperText id="name-error-text">
                {errorMessage}
              </FormHelperText>
            </FormControl>
            {
              addTagAction.isPending && (
                <Fragment>
                  <Spacer small />
                  <CircularProgress />
                </Fragment>
              )
            }
          </div>
        </Dialog>
      </Fragment>
    )
  }
}
