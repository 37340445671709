import { createSelector } from 'reselect'
import { createGetter, composeGetters } from 'react-redux-getters'
import { getCollection, replaceCollections } from '@fakundo/redux-entities'
import find from 'lodash/find'
import filter from 'lodash/filter'
import map from 'lodash/map'
import first from 'lodash/last'
import keyBy from 'lodash/keyBy'
import { fetchLocations } from 'actions/location'
import { LOCATIONS } from 'constants/entityNames'

const ENTITY = LOCATIONS

const findEntityParent = (entities, entityChain) => {
  const entity = first(entityChain)

  if (!entity || !entity.parentUUID) {
    return entityChain
  }

  return [
    ...entities[entity.parentUUID] ? findEntityParent(entities, [entities[entity.parentUUID]]) : [],
    ...entityChain,
  ]
}


const findEntityChildren = (entities, entity) => {
  return map(
    filter(entities, ({ parentUUID }) => entity.uuid === parentUUID),
    ({ uuid }) => uuid
  )
}

export const getLocations = createGetter({
  stateSelector: state => getCollection(state, ENTITY),
  asyncFetcher: dispatch => dispatch(fetchLocations()),
  stateUpdater: (entities, dispatch) => dispatch(replaceCollections({
    [ENTITY]: keyBy(entities, 'uuid')
  })),
})

export const makeGetLocation = () => createSelector(
  state => getLocations(state),
  (state, { uuid }) => uuid,
  (entitiesGetter, entityUUID) => composeGetters(
    entitiesGetter,
    (entities) => {
      const entity = find(entities, ({ uuid }) => uuid === entityUUID) || {}
      return {
        ...entity,
        // children: findEntityChildren(entities, entity)
      }
    }
  )
)

export const makeGetLocationChain = () => createSelector(
  state => getLocations(state),
  (state, { uuid }) => uuid,
  (entitiesGetter, entityUUID) => composeGetters(
    entitiesGetter,
    (entities) => {
      const entity = find(entities, ({ uuid }) => uuid === entityUUID)
      if (!entity) {
        return {}
      }
      return {
        ...entity,
        parents: findEntityParent(entities, [entity]),
        children: findEntityChildren(entities, entity),
      }
    }
  )
)

export const makeGetLocationsChain = () => createSelector(
  state => getLocations(state),
  (state) => state,
  (entitiesGetter) => composeGetters(
    entitiesGetter,
    (entities) => {
      const result = {}
      map(entities, entity => {
        result[entity.uuid] = {
          ...entity,
          parents: findEntityParent(entities, [entity]),
          children: findEntityChildren(entities, entity),
        }
      })
      return result
    }
  )
)

export const makeGetRoot = () => createSelector(
  state => getLocations(state),
  () => 1,
  (entitiesGetter) => composeGetters(
    entitiesGetter,
    (entities) => {
      return find(entities, ({ parentUUID }) => parentUUID === null)
    }
  )
)

export const makeSearchLocationChain = () => createSelector(
  state => getLocations(state),
  (state, { uuid }) => uuid,
  (entitiesGetter, search) => composeGetters(
    entitiesGetter,
    (entities) => {
      const entitiesList = filter(entities, ({ name }) => name.toLowerCase().indexOf(search) !== -1)
      return entitiesList.map(entity => ({
        ...entity,
        parents: findEntityParent(entities, [entity]),
      }))
    }
  )
)
