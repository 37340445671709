/* eslint-disable react/prop-types */
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

export default function Option(option) {
  const { innerRef, isFocused, isSelected, innerProps, data } = option
  const { label, caption } = data
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      <div>
        <div>
          <Typography>
            { label }
          </Typography>
        </div>
        <div>
          <Typography variant="caption">
            { caption }
          </Typography>
        </div>
      </div>
    </MenuItem>
  )
}
