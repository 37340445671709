/* eslint-disable react/prop-types */
import React from 'react'
import TextField from '@material-ui/core/TextField'

import inputComponent from './inputComponent'

export default function Control({ selectProps, innerRef, children, innerProps }) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...selectProps.textFieldProps}
    />
  )
}
