import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'

// Atoms
import ProgressBarOnTop from 'atoms/ProgressBar/ProgressBarOnTop'
import List from '@material-ui/core/List'
import BatchList from 'components/BatchList'

// Actions
import { getBatches } from 'selectors/batches'

const
  styles = () => ({
    list: {
      background: '#fff',
    }
  }),
  mapGettersToProps = (state) => ({
    batchesGetter: getBatches(state),
  })

@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class DataPointListPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    batchesGetter: PropTypes.object.isRequired,
  }

  render() {
    const { batchesGetter, classes } = this.props

    if (!batchesGetter.isSucceded) {
      return <ProgressBarOnTop />
    }

    const batchUUIDList = Object.keys(batchesGetter.data || {})

    return (
      <List className={classes.list}>
        <BatchList batchUUIDList={batchUUIDList} title="Data points" />
      </List>
    )
  }
}
