// Misc
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { connectGetters } from 'react-redux-getters'

// Atoms
import ProgressBarOnTop from 'atoms/ProgressBar/ProgressBarOnTop'

// Selectors
import { getMe } from 'selectors/me'

// Complex routes
import GuestRoutes from './Guest'
import LoggedRoutes from './Logged'


const mapGettersToProps = state => ({
  meGetter: getMe(state)
})

@connectGetters(mapGettersToProps)
export default class Routes extends Component {
  static propTypes = {
    meGetter: PropTypes.object.isRequired,
  }

  render() {
    const { meGetter } = this.props
    if (meGetter.isPending) {
      return <ProgressBarOnTop />
    }

    return (
      <Switch>
        {
          meGetter.isFailed
            ? <Route component={GuestRoutes} />
            : <Route component={LoggedRoutes} />
        }
      </Switch>
    )
  }
}
