// Misc
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

// Atoms
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'

// Styles
const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  small: {
    height: theme.spacing.unit
  },
  default: {
    height: theme.spacing.unit * 2.5
  },
  large: {
    height: theme.spacing.unit * 4
  },
})

@withStyles(styles)
export default class Spacer extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    mobileDivider: PropTypes.bool,
    dividerProps: PropTypes.any,
    small: PropTypes.bool,
    large: PropTypes.bool,
  }

  render() {
    const { mobileDivider, dividerProps, classes, small, large, ...rest } = this.props
    const cx = classNames(classes.root, {
      [classes.default]: !small && !large,
      [classes.small]: small,
      [classes.large]: large,
    })
    return (
      <div {...rest} className={cx}>
        { !!mobileDivider && (
          <Hidden smUp>
            <Divider {...dividerProps} />
          </Hidden>
        )}
      </div>
    )
  }
}
