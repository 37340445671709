import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'

// Elements
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import PersonIcon from '@material-ui/icons/Person'
import Link from 'atoms/Link'

// Selectors
import { getMe } from 'selectors/me'

import MainDrawer from './MainDrawer'

const
  styles = {
    appBar: {
    },
    root: {
      flexGrow: 1,
    },
    flex: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
  },
  mapGettersToProps = state => ({
    meGetter: getMe(state)
  })

@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class Navigation extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    meGetter: PropTypes.object.isRequired,
  }

  state = {
    drawerOpened: false,
  }

  onDrawerClose = () => {
    this.setState({
      drawerOpened: false,
    })
  }

  openDrawer = () => {
    this.setState({
      drawerOpened: true,
    })
  }

  renderLogin = () => {
    return (
      <Link to="/api/v1/integrations/dropbox/redirect" external>
        <Button color="inherit">
          Login
        </Button>
      </Link>
    )
  }

  renderProfile = () => {
    const
      { meGetter } = this.props,
      name = meGetter.data.name || meGetter.data.email || 'Profile'
    return (
      <Link to="/profile">
        <Button color="inherit">
          <PersonIcon />
          { name }
        </Button>
      </Link>
    )
  }

  renderMenu = () => {
    const
      { classes } = this.props
    return (
      <IconButton
        className={classes.menuButton}
        color="inherit"
        aria-label="Menu"
        tabIndex={0}
        role="button"
        onClick={this.openDrawer}
        onKeyDown={this.openDrawer}
      >
        <MenuIcon />
      </IconButton>
    )
  }

  render() {
    const
      { drawerOpened } = this.state,
      { classes, meGetter } = this.props,
      showMenu = !meGetter.isPending && !meGetter.isFailed,
      showLogin = !meGetter.isPending && meGetter.isFailed,
      showProfile = !meGetter.isPending && !meGetter.isFailed

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <MainDrawer open={drawerOpened} onClose={this.onDrawerClose} />
        <Toolbar>
          { showMenu && this.renderMenu() }
          <Typography variant="title" color="inherit" className={classes.flex} />
          { showLogin && this.renderLogin() }
          { showProfile && this.renderProfile() }
        </Toolbar>
      </AppBar>
    )
  }
}
