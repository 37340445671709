import React, { Component } from 'react'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { parseTags } from 'utils/tags'


import Tag from './Tag'


// Styles
const styles = () => ({
  root: {
  }
})

@withStyles(styles)
export default class ExtendedInfo extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  }

  combineTags = (tags, tagsList, selfUUID) => {
    const tagsUsers = {}
    tagsList.forEach(({ tagUUID, userUUID }) => {
      tagsUsers[tagUUID] = [...tagsUsers[tagUUID] || [], userUUID]
    })
    return tags.map(tag => ({
      ...tag,
      created: (tagsUsers[tag.uuid] || []).indexOf(selfUUID) !== -1,
      users: tagsUsers[tag.uuid] || [],
    }))
  }


  render() {
    const
      { classes, image, user } = this.props,
      { tags, tagsList } = image,
      combinedTags = parseTags(tags, tagsList, user).tags

    return (
      <div className={classes.root}>
        {
          map(combinedTags, tag => (<Tag key={tag.uuid} tag={tag} imageUUID={image.uuid} />))
        }
      </div>
    )
  }
}
