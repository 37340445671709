/* eslint-disable react/prop-types */
import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function Placeholder({ selectProps, innerProps, children }) {
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  )
}
