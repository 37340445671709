import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import Link from 'atoms/Link'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import ExtendedInfo from './ExtendedInfo'

// Styles
const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing.unit,
  },
  wrapper: {
    minWidth: 300,
    maxWidth: '100%',
    minHeight: 300,
    maxHeight: '100%',
  },
  image: {
    padding: theme.spacing.unit,
    maxWidth: '100%',
    maxHeight: '100vw',
    outline: 'none',
    cursor: 'pointer',
  },
  link: {
    outline: 'none',
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  }
})

@withStyles(styles)
export default class ImageDialog extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    image: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    loading: true,
  }

  onImageLoad = () => {
    this.setState({ loading: false })
  }

  onImageError = () => {
    this.setState({
      loading: false,
    })
  }

  render() {
    const { classes, open, onClose, image } = this.props
    const { loading } = this.state
    return (
      <Dialog
        className={classes.root}
        open={open}
        onClose={onClose}
      >
        <div className={classes.wrapper}>
          { loading && <LinearProgress className={classes.progressBar} /> }
          <Link external to={image.url} className={classes.link} target="_blank">
            <img
              className={classes.image}
              alt={image.name}
              src={image.url}
              onLoad={this.onImageLoad}
              onError={this.onImageError}
            />
          </Link>
          <Divider />
          <ExtendedInfo image={image} />
        </div>
      </Dialog>
    )
  }
}
