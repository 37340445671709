import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

// Atoms
import Link from 'atoms/Link'

// Styles
const styles = () => ({
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
  }
})

@withStyles(styles)
export default class LocationPath extends Component {
  static propTypes = {
    location: PropTypes.object,
    classes: PropTypes.object.isRequired,
    links: PropTypes.bool,
  }

  handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  renderParent = parentLocation => {
    const { location, classes, links = false } = this.props
    const { parents = [] } = location
    const { uuid, name } = parentLocation
    const lastParentLocation = parents[parents.length - 1].uuid === uuid
    return (
      <Fragment key={uuid}>
        { links && (
          <Link to={`/location/${uuid}/${name}`} className={classes.link}>
            { name }
          </Link>
        )}
        { !links && name }
        {
          !lastParentLocation && ' ╱ '
        }
      </Fragment>
    )
  }

  render() {
    const { location } = this.props

    if (!location) {
      return false
    }

    const { parents = [] } = location

    return (
      <Fragment>
        <span
          tabIndex={0}
          role="link"
          onClick={this.handleClick}
          onKeyPress={() => false}
        >
          {
            parents.slice(1).map(this.renderParent)
          }
        </span>
      </Fragment>
    )
  }
}
