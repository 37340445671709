import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connectGetters } from 'react-redux-getters'
import sortBy from 'lodash/sortBy'

// Atoms
import ImageGrid from 'components/ImageGrid'

// Actions
import { getBatch } from 'selectors/batches'

const
  mapGettersToProps = (state, { batchUUID }) => ({
    batchGetter: getBatch(state, { uuid: batchUUID }),
  })

@connectGetters(mapGettersToProps)
export default class ImageListBatch extends Component {
  static propTypes = {
    batchGetter: PropTypes.object.isRequired,
  }

  render() {
    const { batchGetter } = this.props

    if (!batchGetter.isSucceded) {
      return false
    }

    const
      { images = [] } = batchGetter.data,
      sortedImages = sortBy(images, ({ name }) => name)

    return <ImageGrid images={sortedImages} />
  }
}
