/* eslint-disable react/prop-types */
import React from 'react'
import Paper from '@material-ui/core/Paper'

export default function Menu({ selectProps, innerProps, children }) {
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      { children }
    </Paper>
  )
}
