import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'

// Elements
// import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import PersonIcon from '@material-ui/icons/Person'

// Selectors
import { getMe } from 'selectors/me'

const
  styles = {
    root: {
      flexGrow: 1,
    },
    flex: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
  },
  mapGettersToProps = state => ({
    meGetter: getMe(state)
  })

@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class ProfilePage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    meGetter: PropTypes.object.isRequired,
  }

  renderProfile = () => {
    const
      { meGetter } = this.props,
      name = meGetter.data.name || meGetter.data.email || 'Profile'
    return (
      <Button color="inherit">
        <PersonIcon />
        { name }
      </Button>
    )
  }

  renderMenu = () => {
    const
      { classes } = this.props
    return (
      <IconButton
        className={classes.menuButton}
        color="inherit"
        aria-label="Menu"
        tabIndex={0}
        role="button"
        onClick={this.openDrawer}
        onKeyDown={this.openDrawer}
      >
        <MenuIcon />
      </IconButton>
    )
  }

  render() {
    /* const
      { classes, meGetter } = this.props */
    return (
      <div>
        { this.renderProfile() }
      </div>
    )
  }
}
