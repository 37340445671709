import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connectGetters } from 'react-redux-getters'
import connectActions from 'utils/connectActions'

// Components
import Dialog from 'components/Dialog'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'

import Divider from '@material-ui/core/Divider'
import Spacer from 'atoms/Spacer'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AddLocationIcon from '@material-ui/icons/AddLocation'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
// import Snackbar from '@material-ui/core/Snackbar'

// Selectors
import { makeGetLocationChain } from 'selectors/locations'

// Actions
import { createLocation } from 'actions/location'

// Styles
const styles = (theme) => ({
  root: {
    width: 600,
    maxWidth: '100%',
  },
  parents: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row'
  },
  parentIcon: {
    color: 'rgba(0,0,0,0.5)',
    lineHeight: '1.5rem',
    float: 'left',
  },
  parentArrow: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
  }
})


const mapGettersToProps = () => {
  const getLocation = makeGetLocationChain()
  return (state, { parentUUID }) => ({
    locationGetter: getLocation(state, { uuid: parentUUID }),
  })
}

const
  mapActionsToProps = {
    createLocationAction: createLocation
  }

@connectActions(mapActionsToProps)
@connectGetters(mapGettersToProps)
@withStyles(styles)
export default class NewLocationDialog extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    locationGetter: PropTypes.object.isRequired,
    createLocationAction: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    parentUUID: PropTypes.string.isRequired,
  }

  state = {
    name: '',
    errorOpen: false,
    errorMessage: '',
  }

  onErrorClose = () => {
    this.setState({
      errorOpen: false
    })
  }

  handleChangeName = (event) => {
    const { value } = event.target
    this.setState({
      name: value,
      errorOpen: false,
      errorMessage: '',
    })
  }

  handleCreateLocation = async () => {
    const { parentUUID, createLocationAction, onClose } = this.props
    const { name } = this.state

    try {
      await createLocationAction.run({
        params: [{
          name,
          parentUUID,
        }],
      })
      onClose()
      this.setState({
        name: '',
      })
    } catch (error) {
      this.setState({
        errorOpen: true,
        errorMessage: error.message,
      })
    }
  }

  renderParent = (parent, id) => {
    const { classes } = this.props
    const isLast = id === undefined
    return (
      <Fragment key={id}>
        <Typography className={classes.parentName} variant="subheading">
          { !isLast && <LocationOnIcon className={classes.parentIcon} /> }
          { isLast && <AddLocationIcon className={classes.parentIcon} /> }
          { parent.name }
        </Typography>
        { !isLast && (
          <Typography className={classes.parentArrow} variant="subheading">
            →
          </Typography>
        )}
      </Fragment>
    )
  }

  renderParents = (name) => {
    const { classes, locationGetter: { data: { parents } } } = this.props
    return (
      <div className={classes.parents}>
        { parents.map(this.renderParent) }
        { this.renderParent({ name }) }
      </div>
    )
  }

  renderButtons = () => {
    const { classes, onClose } = this.props
    return (
      <Fragment>
        <Button color="primary" className={classes.button} onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" className={classes.button} onClick={this.handleCreateLocation}>
          Confirm
        </Button>
      </Fragment>
    )
  }

  render() {
    const { classes } = this.props
    const { locationGetter, createLocationAction, open, onClose } = this.props
    const { name, errorOpen, errorMessage } = this.state

    if (!locationGetter.isSucceded) {
      return false
    }

    return (
      <Fragment>
        <Dialog title="New location" open={open} onClose={onClose} buttons={!createLocationAction.isPending && this.renderButtons()}>
          <div className={classes.root}>
            <Divider light />
            <Spacer small />
            {
              this.renderParents(name)
            }
            <Spacer small />
            <Divider light />
            <Spacer small />
            <FormControl className={classes.root} error={errorOpen}>
              <InputLabel>
                Location name
              </InputLabel>
              <Input value={name} onChange={this.handleChangeName} />
              <FormHelperText id="name-error-text">
                {errorMessage}
              </FormHelperText>
            </FormControl>
            {
              createLocationAction.isPending && (
                <Fragment>
                  <Spacer small />
                  <CircularProgress />
                </Fragment>
              )
            }
          </div>
        </Dialog>
      </Fragment>
    )
  }
}


/*

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import connectActions from 'utils/connectActions'

// Atoms
import ProgressBarOnTop from 'atoms/ProgressBar/ProgressBarOnTop'

// Actions
import { listPath } from 'actions/cloud'

import List from './List'

const
  mapActionsToProps = {
    listPathAction: listPath
  }

@connectActions(mapActionsToProps)
export default class DropboxListPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    listPathAction: PropTypes.object.isRequired,
  }

  state = {
    folderData: false,
    path: false,
  }

  componentDidMount = async () => {
    this.loadPath()
  }

  async componentDidUpdate(prevProps) {
    const { location } = this.props
    if (prevProps.location.pathname !== location.pathname) {
      this.loadPath()
    }
  }

  loadPath = async () => {
    const
      { location, match, listPathAction } = this.props,
      path = location.pathname.split(match.path)[1].trim().replace(/\/\//g, '/'),
      folderData = await listPathAction.run({
        params: [path === '/' ? '' : path],
      })

    this.setState({
      folderData,
      path,
    })
  }

  render() {
    const
      { listPathAction } = this.props,
      { folderData, path } = this.state

    if (listPathAction.isPending || !folderData) {
      return <ProgressBarOnTop />
    }

    return <List folderData={folderData} path={path} />
  }
}


*/
